import styled from "@emotion/styled";
import React from "react";

export default function FloatingScrollMarker() {
  return (
    <FloatWrap>
      <div>
        <span></span>
      </div>
    </FloatWrap>
  );
}

const FloatWrap = styled.div`
  position: absolute;
  bottom: 140px;
  left: 18px;
  z-index: 100;

  @media (min-width: 769px) {
    display: none;
  }

  div {
    display: flex;
    flex-direction: column;
    z-index: 100;
  }

  span {
    display: inline-block;
    right: 40px;
    bottom: 0;
    width: 1px;
    height: 80px;
  }

  span::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    width: 1px;
    height: 100px;
    background: rgba(255, 255, 255, 0.35);
  }
  span::after {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    width: 1px;
    height: 100px;
    background: white;
    animation: sdl 2s cubic-bezier(0.72, -0.16, 0.42, 1.1) infinite;
  }

  @keyframes sdl {
    0% {
      transform: scale(1, 0);
      transform-origin: 0 0;
    }
    50% {
      transform: scale(1, 1);
      transform-origin: 0 0;
    }
    50.1% {
      transform: scale(1, 1);
      transform-origin: 0 100%;
    }
    100% {
      transform: scale(1, 0);
      transform-origin: 0 100%;
    }
  }
`;
