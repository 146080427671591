import styled from "@emotion/styled";
import {StaticImage} from "gatsby-plugin-image";
import React from "react";
import Layout from "../components/layout";
import BackImage from "../components/pages/home/BackImage";
import CategoryDescription from "../components/pages/home/CategoryDescription";
import FloatingScrollMarker from "../components/pages/home/FloatingScrollMarker";
import CoverView2 from "../components/common/CoverView2";

export default function Home() {
  return (
    <Layout>
      <Container>
        <CoverView2 image={
          <StaticImage
              src="../assets/img/index/top.jpg"
              alt="top"
              objectFit="cover"
              layout="fullWidth"
              quality={100}
              className="top-cover"
          />
        }>
          <FloatingScrollMarker></FloatingScrollMarker>
        </CoverView2>
        {/*<Cover>*/}
        {/*  <FloatingScrollMarker></FloatingScrollMarker>*/}
        {/*</Cover>*/}
        <BackImage></BackImage>

        <div className="bl-links">
          <div className="bl-about">
            <div className="bl-about-images">
              <StaticImage
                src="../assets/img/index/about.jpg"
                alt="about"
                className="grid-image"
                objectFit="cover"
                objectPosition="right"
                loading="eager"
                quality={100}
              />
            </div>

            <CategoryDescription
              en="About"
              jp="ニットロジーについて"
              link_to="/about"
              link_name="ニットロジーについて"
            >
              創業以来、KNITOLOGYを大切に扱ってくださっている
              <br className="hideSP" />
              取扱店舗や製造工場について紹介します。
            </CategoryDescription>
          </div>
          <div className="bl-env">
            <div className="bl-env-images">
              <StaticImage
                src="../assets/img/index/machine.jpg"
                alt="our product"
                className="grid-image"
                objectFit="cover"
                layout="fullWidth"
                quality={100}
              />
            </div>

            <CategoryDescription
              en="Studio"
              jp="モノが生まれる環境"
              link_to="/studio"
              link_name="製作環境の紹介"
            >
              KNIT + LOGY = ニット学
              <br />
              <br className="hidePC" />
              実験的に模索しながら、ニットを学問のように追求していく
              <br className="hideSP" />
              デザイン、生地、生産体制、販売、触感、環境
              <br className="hideSP" />
              あらゆる段階をじっくり納得のいくものを作っています。
            </CategoryDescription>
          </div>

          <div className="bl-our-make">
            <div className="bl-our-make-images">
              <StaticImage
                src="../assets/img/index/our0.jpg"
                alt="our product"
                className="grid-image sp-2"
                objectFit="cover"
                objectPosition="top"
                quality={100}
              />
              <StaticImage
                src="../assets/img/index/our1.jpg"
                alt="workwear"
                className="grid-image"
                objectFit="cover"
                objectPosition="top"
                quality={100}
              />
              <StaticImage
                src="../assets/img/index/our2.jpg"
                alt="workwear"
                className="grid-image"
                objectFit="cover"
                objectPosition="top"
                quality={100}
              />
            </div>

            <CategoryDescription
              en="Product"
              jp="わたしたちのモノづくり"
              link_to="/product"
              link_name="プロダクトをもっと知る"
            >
              これさえあればいい。そう思ってもらえる至高の一着に。
              <br />
              着心地を追求したWORK WEAR、素材を追求したTextureLAB、
              <br className="hideSP" />
              新しい衣服環境を目指したhome couture。
              <br />
              生き方を豊かにするコレクションをご覧ください。
              <br />
            </CategoryDescription>
          </div>
        </div>
      </Container>
    </Layout>
  );
}


const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: black;
  height: 100%;

  .bl-our-make {
    margin-top: 140px;
  }

  .bl-our-make-images {
    display: grid;
    gap: 0px;
    grid-template-columns: 2fr 1fr 1fr;

    .grid-image {
      width: 100%;
    }

    @media (max-width: 768px) {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;

      .sp-2 {
        grid-column: span 2;
      }
    }
  }

  .bl-env {
    margin-top: 140px;
    width: 100%;
  }

  .bl-links {
    margin-bottom: 200px;

    & > * {
      margin-top: 160px;
    }
  }

  .bl-about {
    width: 100%;
  }

  .bl-env-images {
    width: 80%;
    margin: 0 auto;

    @media (max-width: 768px) {
      height: 300px;

      .grid-image {
        width: 100%;
        height: 100%;
      }
    }
  }

  .bl-about-images {
    width: 80%;
    max-width: 1182px;

    @media (max-width: 768px) {
      height: 300px;
      width: 100%;

      .grid-image {
        width: 100%;
        height: 100%;
      }
    }
  }
`;
