import styled from "@emotion/styled";
import React from "react";
import {Link} from "gatsby";
import ArrowButton from "../../common/ArrowButton";

interface CategoryDescriptionProps {
    children: React.ReactNode;
    en: string;
    jp: string;
    link_to: string;
    link_name: string;
}

export default function CategoryDescription(props: CategoryDescriptionProps) {
    return (
        <Container>
            <div className="bl-out-make-desc">
                <div className="our-title">
                    <span className="out-title-en">{props.en}</span>
                    <h3 className="our-title-jp">{props.jp}</h3>
                </div>
                <div className="our-message">
                    <p>{props.children}</p>

                    <div className="underline-link">
                        <Link className="underline-link-text" to={props.link_to}>
                            <span>{props.link_name}</span>
                            <span className="arrow">
                                <svg xmlns="http://www.w3.org/2000/svg" width="10.344" height="10.04" viewBox="0 0 10.344 10.04">
                                    <g id="グループ_5672" data-name="arrow" transform="translate(-1376.82 -3714.91)">
                                        <line id="線_360" data-name="line" y1="9.181" x2="9.451" transform="translate(1377.168 3715.41)" fill="none"
                                              stroke="#fff" strokeWidth="1"/>
                                        <path id="パス_26934" data-name="p" d="M-3282.578-14341.5h8.833v9.182" transform="translate(4660.41 18056.91)"
                                              fill="none" stroke="#fff"
                                              strokeWidth="1"/>
                                    </g>
                                </svg>
                            </span>
                        </Link>
            </div>
                </div>
            </div>
        </Container>
    );
}

const Container = styled.div`


  .bl-out-make-desc {

    margin-top: 52px;
    margin-left: 10px;
    padding: 0 30px;
    display: flex;
    justify-content: space-between;
    width: calc(100% - 60px);
    
    @media (max-width: 768px) {
      flex-direction: column;
      padding: 0 15px;
      margin-left: 0px;
      width: 100%;
    }
  }

  .out-title-en {
    font-weight: normal;
    font-size: 18px;
    letter-spacing: 0.08em;
    text-align: center;
    color: #9b8750;
  }

  .our-title-jp {
    margin-top: 12px;
    font-weight: normal;
    font-size: 31px;
    letter-spacing: 0.08em;
    line-height: 42px;
    text-align: left;
    color: #9b8750;

    @media (max-width: 768px) {
      font-size: 24px;
      font-weight: bold;
    }
  }

  .our-title {
    flex-basis: 40%;
    margin-right: 30px;
  }

  .our-message {
    color: #fff;
    flex-basis: 60%;
    margin-top: 30px;

    @media (max-width: 768px) {
      margin-top: 30px;
    }

    p {
      font-weight: 100;
      font-size: 18px;
      letter-spacing: 0.1em;
      line-height: 32px;
      text-align: left;
      color: #999;

      @media (max-width: 768px) {
        font-size: 16px;
      }

    }
  }

  .underline-link {
    
    width: fit-content;
    display: block;
    padding:20px 70px;
    margin: 50px 0 0 auto;
    text-align: center;
    background-color: #333;
    border-radius: 20px;
    cursor: pointer;

    @media (max-width: 768px) {
      width: 100%;
      margin: 60px auto;
      padding:20px 20px;
    }

    .underline-link-text {

      display: block;
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 0.1em;
      line-height: 30px;
      color: #fff;
    }

    &:hover{
      background-color: #9B8750;
    }
    .arrow {
      display: inline-block;
      margin-left: 30px;

      &:hover {
        color: #9B8750;
        transform: translate(0%, 0%);
        transition: transform 0.6s cubic-bezier(0.4, 0, 0, 1)
      }
    }

    @keyframes bounceAlpha {
      0% {
        opacity: 1;
        transform: translate(0, 0) scale(1);
      }
      25% {
        opacity: 0;
        transform: translate(10px, -10px) scale(0.9);
      }
      26% {
        opacity: 0;
        transform: translate(-10px, 10px) scale(0.9);
      }
      55% {
        opacity: 1;
        transform: translate(0, 0) scale(1);
      }
    }

    .bounceAlpha {
      animation-name: bounceAlpha;
      animation-duration: 1s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }

    .arrow.bounceAlpha {
      animation-name: bounceAlpha;
      animation-duration: 1s;
      animation-delay: 0.2s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }

    &:hover .arrow {
      animation-name: bounceAlpha;
      animation-duration: 1s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }

    .underline-link-text:hover .arrow {
      animation-name: bounceAlpha;
      animation-duration: 1.4s;
      animation-delay: 0.2s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }

  }
`;
