import styled from "@emotion/styled";

import React from "react";
import { useInView } from "react-intersection-observer";
import img from "../../../../assets/img/index/pc1-2.jpg";
import { BackImageText } from "./BackImageText";
export default function BackImage() {
  const { ref, inView } = useInView({
    threshold: 0.7,
    triggerOnce: true,
  });
  return (
    <BackImageContainer>
      <ImageWrap ref={ref}>
        <ContentWrap>
          <TitleWrap>
            <p>
              ヒトは、
              <br />
              不思議で
              <br />
              美しい。
            </p>
          </TitleWrap>
          <DescriptionWrap>
            <BackImageText inView={inView} />
          </DescriptionWrap>
        </ContentWrap>
      </ImageWrap>
    </BackImageContainer>
  );
}
const TitleWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-top: 95px;
  padding-bottom: 75px;

  p {
    transition: opacity 1.5s ease-out;
    //font-family: "游明朝", "Yu Mincho", "YuMincho", serif;
    font-weight: 500;
    font-size: 44px;
    letter-spacing: 0.36em;
    line-height: 82px;
    text-align: left;
    color: #fff;

    @media (max-width: 768px) {
      font-size: 30px;
      letter-spacing: 0.36em;
      line-height: 56px;
      text-align: left;
    }
  }
`;

const DescriptionWrap = styled.div`
  width: 100%;
`;

const BackImageContainer = styled.div`
  width: 100%;
  height: 100%;
  margin-top:160px;
  @media (min-width: 769px) {
    padding-bottom: 160px;
  }
`;

const ContentWrap = styled.div`
  max-width: 1000px;
  width: 100%;
  height: 100%;
  margin-left: 30px;
  margin-right: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: 769px) {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
`;

const ImageWrap = styled.div`
  background-image: url(${img});
  background-position-x: center;
  background-repeat: no-repeat;

  display: flex;
  align-items: center;
  height: 722px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (min-width: 769px) {
    width: 100%;
    height: 768px;
    background-size: contain;

    img {
      width: 100%;
      height: auto;
      object-fit: inherit;
    }
  }
`;
