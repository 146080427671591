import styled from "@emotion/styled";
import React from "react";

interface BackImageProps {
  inView: boolean;
}

export function BackImageText(props: BackImageProps) {
  return (
    <TextWraper>
      衣を通して、人間とは何かを問う。
      <br/>
      私にとって<span className="big-span">「人間」</span>という
      <br className="br-pc" />
      最も根源的な存在を受け止めることが、衣服作りの原点である。
      <br className="br-pc" />
      人は、生まれた時点ですでに美しく、愛おしい存在だ。
      <br className="br-pc" />
      このことが全ての起点になっている。
      <br className="br-pc" />
      あらゆる肉体はそれぞれに異なるカタチと歴史が息づいている。
      <br className="br-pc" />
      敬意をもち、そのままの姿を大切にしたい。
      <br className="br-pc" />
      あるがままを美しくする。それが私の使命です。
    </TextWraper>
  );
}

const TextWraper = styled.p`
  font-weight: 500;
  font-size: 21px;
  letter-spacing: 0.1em;
  line-height: 56px;
  text-align: left;
  color: #ccc;
  
  transition: opacity 1s ease-out;
  display: inline-block;
  
  .big-span{
    font-size: 1.6em;
  }

  @media (max-width: 768px) {
    .br-pc {
      display: none;
    }

    font-size: min(18px, 4.6vw);
    letter-spacing: 0.08em;
    line-height: 2em;
    margin-left: auto;
    margin-right: auto;
  }
`;
